import { UploadInputStudy, UploadInstanceManifest } from '../../../sharedTypes/bucketUploadTypes'
import { DicomFilesMap } from './types';
import { v4 as uuidv4 } from 'uuid'
import type { StudyMetadata } from '../providers/FileStateProvider';


const systemFiles: string[] = [".DS_Store"]

/**
 * Handles filtering of uploaded files
 */
const filterFiles = (unfilteredFiles: File[]): File[] => {
  // Filter out bad files
  const filteredFiles = Array.from(unfilteredFiles).filter(file => {
    if (systemFiles.includes(file.name)) {
      // Skip any OS files uploaded (not there might be more)
      return false
    }

    return true
  })

  return filteredFiles
};

const getUniqueFileName = (fileName: string): string => {
  return `${uuidv4()}.${fileName}`;
}

const getUploadInputStudiesFromStudyMetadata = (studyMetadata: StudyMetadata[]): UploadInputStudy[] => {
  return studyMetadata.map(study => {
    const { header } = study;

    return {
      StudyInstanceUID: header.StudyInstanceUID,
      StudyDate: header.StudyDate,
      StudyTime: header.StudyTime,
      TimezoneOffsetFromUTC: header.TimezoneOffsetFromUTC,
      ModalitiesInStudy: study.ModalitiesInStudy
    };
  })
}

const generateUploadManifestAndFileMapFromFiles = (dicomFilesMap: DicomFilesMap, studyMetadata: StudyMetadata[], attachments: File[]): {
  manifest: UploadInstanceManifest,
  fileMap: Record<string, File>
} => {
  const studies = getUploadInputStudiesFromStudyMetadata(studyMetadata);

  const manifest: UploadInstanceManifest = {
    studies,
    dicom: [],
    attachments: []
  }

  const fileMap: Record<string, File> = {}

  Object.keys(dicomFilesMap).forEach(StudyInstanceUID => {
    const files = dicomFilesMap[StudyInstanceUID]

    files.forEach(file => {
      const uniqueFileName = getUniqueFileName(file.name)
      manifest.dicom.push(uniqueFileName)
      fileMap[uniqueFileName] = file
    })
  })

  attachments.forEach(file => {
    const uniqueFileName = getUniqueFileName(file.name)
    manifest.attachments.push(uniqueFileName)
    fileMap[uniqueFileName] = file
  })

  return { manifest, fileMap };
}

export {
  generateUploadManifestAndFileMapFromFiles,
  filterFiles
}